class Calendar {
    constructor(selector) {
        this.selector = selector;
        this.calendar_container = this.selector.querySelector('[data-calendar-container]');
        this.items_container = this.calendar_container.querySelector('[data-items-container]');
        this.items = this.items_container.querySelectorAll('[data-item]');
        this.content_container = this.selector.querySelector('[data-content]');
        this.cache = {};
        this.isMobile = false;

        if (this.items !== null) {
            this.items.forEach(item => {
                this._clickItemEvent = this._clickItemEvent.bind(this);
                item.addEventListener('click', this._clickItemEvent);
            });
        }

        if (window.innerWidth <= 500) {
            this.isMobile = true;
        }

        this._clickFirstElement();
    }
    _clickItemEvent(e) {
        e.preventDefault();

        let post_id = e.target.getAttribute('data-post-id');
        this._loadPost(post_id);

        this._setActiveItem(e.target);
    }
    _setActiveItem(item) {
        this._clearItems();
        item.classList.add('calendar__timeline-item--active');

        if (this.isMobile) {
            setTimeout(() => {
                window.scrollTo({
                    top: window.pageYOffset + this.content_container.getBoundingClientRect().top - 20,
                    behavior: 'smooth'
                });
            },100);
        }
    }
    _clearItems() {
        this._clearContent();
        if (this.items !== null) {
            this.items.forEach(item => {
                if (item.classList.contains('calendar__timeline-item--active')) {
                    item.classList.remove('calendar__timeline-item--active');
                }
            });
        }
    }
    _clearContent() {
        this.content_container.innerHTML = '';
    }
    _getFromAPI(post_id) {
        if (this.cache.hasOwnProperty('post_' + post_id)) {
            return new Promise((resolve, reject) => {
                resolve(this.cache['post_' + post_id]);
            });
        }

        let url = '/wp-json/wp/v2/calendar?per_page=1&_embed&include[]=' + post_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _loadPost(post_id){
        this._getFromAPI(post_id).then(response => {
            if(response.length > 0){
                if (!this.cache.hasOwnProperty('post_' + post_id)) {
                    this.cache['post_' + post_id] = response;
                }
                this._generateContent(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
        console.log('Cache: ' + this.cache);
    }
    _generateContent(res) {
        let content = document.createElement('div');
        content.classList.add('calendar__content');

        let close = document.createElement('a');
        close.classList.add('calendar__close');
        close.innerHTML = '×';

        close.addEventListener('click', e => {
            e.preventDefault();

            this._clearItems();

            setTimeout(() => {
                if (this.isMobile) {
                    window.scrollTo({
                        top: window.pageYOffset + this.calendar_container.getBoundingClientRect().top,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        });

        let image = null;
        if (typeof res[0]._embedded !== 'undefined') {
            image = document.createElement('img');
            image.classList.add('calendar__image');
            image.setAttribute('alt', res[0].title.rendered);
            image.setAttribute('src', res[0]._embedded["wp:featuredmedia"][0].source_url);
        }

        let date = document.createElement('div');
        date.classList.add('calendar__date', 'title', 'title--red', 'title--uppercase');
        date.innerHTML = res[0].nt_date;

        let title = document.createElement('h3');
        title.classList.add('calendar__title', 'title', 'title--red', 'title--uppercase');
        title.innerHTML = res[0].title.rendered;

        let text = document.createElement('div');
        text.classList.add('calendar__text');
        text.innerHTML = res[0].content.rendered;

        if (image !== null) {
            content.appendChild(image);
        }
        content.appendChild(date);
        content.appendChild(title);
        content.appendChild(text);

        this.content_container.innerHTML = '';
        this.content_container.appendChild(close);
        this.content_container.appendChild(content);
    }
    _noResults(){
        let text = document.createElement('div');
        // text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Brak wyników.';

        // this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('div');
        // text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Wystąpił bląd.';

        // this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _clickFirstElement() {
        let first_element = this.items_container.querySelector('[data-item]:first-of-type');
        if (first_element !== null) {
            first_element.click();
        }
    }
}
export default Calendar;