import * as basicLightbox from "basiclightbox";

class SourceText {
    constructor(selector) {
        this.selector = selector;

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);

        this.content = window.source_text;
        console.log(this.content);
    }

    _clickEvent(e) {
        e.preventDefault();

        if (this.content !== '') {
            const div = document.createElement('div');
            const modal = document.createElement('div');
            const content = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--text')
            modal.setAttribute('data-element', 'modal');

            content.classList.add('modal__content');
            content.innerHTML = this.content;

            modal.appendChild(close);
            modal.appendChild(content);
            div.appendChild(modal);

            let instance = basicLightbox.create(div);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
    }
}
export default SourceText;