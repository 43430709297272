import Cookie from 'js-cookie';
import * as basicLightbox from "basiclightbox";

class Quiz {
    constructor(selector) {
        this.selector = selector;

        this.questionContainer = selector.querySelector('[data-element="question-container"]');
        this.formContainer = selector.querySelector('[data-element="form"]');
        this.question = selector.querySelector('[data-element="question"]');
        this.answersContainer = selector.querySelector('[data-element="answers"]');
        this.scoreContainer = selector.querySelector('[data-element="score"]');
        this.nextButton = selector.querySelector('[data-element="next"]');
        this.clearButton = selector.querySelector('[data-element="clear"]');
        this.endButton = selector.querySelector('[data-element="end"]');
        this.scoreText = selector.querySelector('[data-element="score"]');

        this.questionCount = selector.getAttribute('data-count');
        this.quiz = selector.getAttribute('data-id');
        this.lesson = selector.getAttribute('data-lesson');
        this.type = selector.getAttribute('data-type');
        this.seminar = selector.getAttribute('data-seminar');

        this.currentQuestion = 0;
        this.score = 0;

        this._nextQuestion = this._nextQuestion.bind(this);
        this._clearQuiz = this._clearQuiz.bind(this);
        this._sendForm = this._sendForm.bind(this);

        this.nextButton.addEventListener('click', this._nextQuestion);
        this.clearButton.addEventListener('click', this._clearQuiz);

        console.log('Form container ' + this.formContainer);
    }

    _nextQuestion(event){
        event.preventDefault();
        let checkedAnswer = this._getCheckedAnswer();
        if(checkedAnswer !== -1){
            this._checkAnswer(checkedAnswer).then(result => {
                if(result.correct){
                    this.score++;
                }
                if(result.nextQuestion !== null){
                    this._generateNextQuestion(result.nextQuestion);
                    this.currentQuestion++;

                    if (this.currentQuestion === this.questionCount-1) {
                        this.nextButton.innerText = window.i18n_quiz.end_quiz;
                    }
                } else {

                    this.is_get_cart = false;
                    if (this.endButton.hasAttribute('data-certificate')) {
                        this.is_get_cart = true;
                        this.permalink = this.endButton.getAttribute('href');
                    }
                    this._showScore();
                }
            }).catch(error => {
                this._handleError(error);
            })
        } else {
            this._handleError('no_answer_selected');
        }
    }

    _setLessonCleared(){
        let current = Cookie.get('completed_' + this.lesson);
        let cleared = [];
        if(current !== undefined){
            cleared = current.split(',');
        }
        if (!cleared.includes(this.quiz)) {
            cleared.push(this.quiz);
        }
        Cookie.set('completed_' + this.lesson, cleared.join(','), { expires: 365 });
    }

    _generateNextQuestion(question){
        if(question.answers.length > 0){
            this.question.innerText = question.question;
            this.answersContainer.innerHTML = '';
            question.answers.forEach((answer, key) => {
                let answerElement = this._createAnswerElement(key, answer.answer);
                this.answersContainer.appendChild(answerElement);
            });
        }
    }

    _showScore(){
        if(this.score == this.questionCount){
            this._setLessonCleared();

            if (this.is_get_cart) {
                this.nextButton.style.display = 'none';
                this._showLastStep();
                if (this.type === 'seminar' && typeof this.seminar !== 'undefined') {
                    Cookie.set('seminar_cleared_' + this.seminar, true, { expires: 365 });
                }
            } else {
                this.selector.classList.add('lesson__box-content--quiz-overlay');
                this.questionContainer.style.display = 'none';
                this.scoreContainer.style.margin = '30px';
                this.scoreText.innerText = window.i18n_quiz.score + this.score + '/' + this.questionCount;
                this.endButton.style.display = 'block';
                this.nextButton.style.display = 'none';
            }
        } else {
            this.selector.classList.add('lesson__box-content--quiz-overlay');
            this.questionContainer.style.display = 'none';
            this.scoreContainer.style.margin = '30px';
            this.scoreText.innerText = window.i18n_quiz.score + this.score + '/' + this.questionCount;
            this.clearButton.style.display = 'block';
            this.nextButton.style.display = 'none';
        }

    }

    _handleError(error){
        console.log(error);
        let element = document.createElement('div');

        const modal = document.createElement('div');
        const close = document.createElement('a');
        close.classList.add('modal__close');
        close.innerHTML = '×';

        modal.classList.add('modal');
        modal.classList.add('modal--text', 'modal--quiz-error');
        modal.setAttribute('data-element', 'modal');

        if (error === 'no_answer_selected') {
            modal.innerText = window.i18n_quiz.no_answer;
        } else {
            modal.innerText = window.i18n_quiz.error;
        }

        modal.appendChild(close);
        element.appendChild(modal);

        let instance = basicLightbox.create(element);
        instance.show();

        close.addEventListener('click', (e) => {
            instance.close();
            instance.innerHTML = '';
        });
        // alert(error);
    }

    _createAnswerElement(id, answer){
        let answerElement = document.createElement('div');
        answerElement.classList.add('lesson__quiz-answer');
        let input = document.createElement('input');
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'answer');
        input.setAttribute('value', id);
        input.setAttribute('id', 'answer-'+id);
        answerElement.appendChild(input);
        let label = document.createElement('label');
        label.setAttribute('for', 'answer-'+id);
        if(this._isValidURL(answer)){
            let image = document.createElement('img');
            image.setAttribute('src', answer);
            label.appendChild(image);
        } else {
            label.innerHTML = answer;
        }
        answerElement.appendChild(label);
        return answerElement;
    }

    _isValidURL(u){
        let elm = document.createElement('input');
        elm.setAttribute('type', 'url');
        elm.value = u;
        return elm.validity.valid;
    }

    _getCheckedAnswer(){
        let checked = this.answersContainer.querySelector('[name="answer"]:checked');
        if(checked !== null){
            return checked.getAttribute('value');
        }
        return -1;
    }

    _checkAnswer(answer){
        let url = '/wp-json/netivo/v1/quiz/'+this.quiz;
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            body: 'question='+this.currentQuestion+'&answer='+answer,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _getQuestion(question){
        let url = '/wp-json/netivo/v1/quiz/'+this.quiz+'?question='+question;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _clearQuiz(event){
        event.preventDefault();
        this.score = 0;
        this.currentQuestion = 0;
        this.clearButton.style.display = 'none';
        this.nextButton.innerText = 'Dalej';
        this.nextButton.style.display = 'block';
        this.selector.classList.remove('lesson__box-content--quiz-overlay');
        this.scoreText.innerText = '';
        this.questionContainer.style.display = 'block';
        this.scoreContainer.style.margin = '0';

        this._getQuestion(0).then(question => {
            this._generateNextQuestion(question);
        }).catch(error => {
            this._handleError(error);
        });
    }
    _generateForm(){
        let form = document.createElement('form');
        form.classList.add('form')

        let form_row_name = document.createElement('div');
        form_row_name.classList.add('form__row');

        let form_input_group_name = document.createElement('div');
        form_input_group_name.classList.add('form__input-group');

        let form_input_label_name = document.createElement('label');
        form_input_label_name.setAttribute('for', 'name');
        form_input_label_name.innerText = window.i18n_quiz.form_name;

        let nameInput = document.createElement('input');
        nameInput.setAttribute('name', 'name');
        nameInput.setAttribute('type', 'text');
        nameInput.setAttribute('required', 'required');
        nameInput.setAttribute('placeholder', window.i18n_quiz.form_name);

        let form_input_group_email = document.createElement('div');
        form_input_group_email.classList.add('form__input-group');

        let form_input_label_email = document.createElement('label');
        form_input_label_email.setAttribute('for', 'name');
        form_input_label_email.innerText = window.i18n_quiz.form_email;

        let emailInput = document.createElement('input');
        emailInput.setAttribute('name', 'email');
        emailInput.setAttribute('type', 'email');
        emailInput.setAttribute('required', 'required');
        emailInput.setAttribute('placeholder', window.i18n_quiz.form_email);

        let form_row_rules = document.createElement('div');
        form_row_rules.classList.add('form__row');

        let label_rules = document.createElement('label');
        label_rules.classList.add('form__rules');

        let agreement = document.createElement('input');
        agreement.setAttribute('type', 'checkbox');
        agreement.setAttribute('required', 'required');
        agreement.setAttribute('id', 'agreement');

        let span = document.createElement('span');
        span.innerHTML = window.i18n_quiz.form_agreement;

        let form_row_button = document.createElement('div');
        form_row_button.classList.add('form__row');

        let button = document.createElement('button');
        button.setAttribute('type', 'submit');
        button.innerHTML = window.i18n_quiz.button_send;
        button.classList.add('button');
        // button.classList.add('button button--bg-blue');

        let button_cancel = document.createElement('a');
        button_cancel.innerHTML = window.i18n_quiz.button_cancel;
        button_cancel.classList.add('button', 'button--blue');
        button_cancel.setAttribute('href', this.permalink);

        form_input_group_name.append(form_input_label_name);
        form_input_group_name.append(nameInput);

        form_input_group_email.append(form_input_label_email);
        form_input_group_email.append(emailInput);

        form_row_name.append(form_input_group_name);
        form_row_name.append(form_input_group_email);

        label_rules.append(agreement);
        label_rules.append(span);
        form_row_rules.append(label_rules);

        form_row_button.append(button);
        form_row_button.append(button_cancel);

        form.append(form_row_name);
        form.append(form_row_rules);
        form.append(form_row_button);
        return form;
    }

    _sendForm(e){
        e.preventDefault();

        console.log(this.formContainer);

        let data = {
            name: '',
            email: ''
        };

        let nameInput = this.formContainer.querySelector('[name="name"]');
        let emailInput = this.formContainer.querySelector('[name="email"]');
        if(nameInput !== null) data.name = nameInput.value;
        if(emailInput !== null) data.email = emailInput.value;

        this._submitForm(data).then(result => {
            this.formContainer.innerHTML = '';
            this.formContainer.style.display = 'none';
            this.scoreContainer.classList.add('lesson__quiz-score--static');
            this.scoreContainer.classList.add('lesson__quiz-score--static-end');
            this.scoreContainer.innerText = window.i18n_quiz.thank_you_cert;
            this.scoreContainer.style.display = 'block';
        }).catch(error => {
            this._handleError(error);
        })
    }

    _submitForm(data){
        let formBody = Object.keys(data).map(key => {
            if (typeof data[key] === "object" && !Array.isArray(data[key]) && data[key] !== null) {
                return Object.keys(data[key]).map(k => {
                    return key + '[' + k + ']=' + data[key][k];
                }).join('&');
            }
            return key + '=' + data[key]
        }).join('&');
        let url = '/wp-json/netivo/v1/quiz/' + this.quiz;
        return fetch(url, {
            method: 'PUT',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _showLastStep(){
        let form = this._generateForm();

        form.addEventListener('submit', this._sendForm);

        let msg = document.createElement('div');

        let score = document.createElement('div');
        score.classList.add('lesson__quiz-score');
        score.classList.add('lesson__quiz-score--static');
        score.innerHTML = (this.type === 'seminar') ? window.i18n_quiz.finish_seminar : window.i18n_quiz.finish_lesson;

        let info = document.createElement('div');
        info.classList.add('lesson__quiz-info');
        info.innerHTML = window.i18n_quiz.ask_certificate;

        msg.append(score);
        msg.append(info);

        this.formContainer.append(msg);
        this.formContainer.append(form);
        this.questionContainer.style.display = 'none';
        this.nextButton.style.display = 'none';
        this.formContainer.style.display = 'block';
    }
}

export default Quiz;