import GLightbox from "glightbox";
class DynamicGallery {
    constructor(selector) {
        this.selector = selector;
        this.type = this.selector.getAttribute('data-type');
        this.photos = null;

        if (this.type === 'old-photos') {
            this.photos = window.old_photos_gallery;
        } else this.photos = window.knowledge_gallery;

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
    }

    _clickEvent(e) {
        console.log(this.photos);
        e.preventDefault();

        const gallery = GLightbox({
           elements: this.photos
        });
        gallery.open();
    }
}
export default DynamicGallery;