import * as basicLightbox from "basiclightbox";

class ExtendedMaterials {
    constructor(selector) {
        this.selector = selector;

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);

        this.content = window.extended_materials;
        this.video_id = window.youtube_video_id;
        this.pdfs = window.pdfs;

        this.nt_lang_strings = {
            translations: {
                movie_title: {
                    pl: 'Film',
                    en: 'Movie',
                    he: ''
                },
                files_title: {
                    pl: 'Pliki',
                    en: 'Files',
                    he: ''
                },
            }
        };
    }

    _clickEvent(e) {
        e.preventDefault();

        if (this.content !== '') {
            const div = document.createElement('div');
            const modal = document.createElement('div');
            const content = document.createElement('div');
            let content_text = document.createElement('div');
            let content_movie = document.createElement('div');
            content_movie.classList.add('modal__movie');
            let content_movie_title = document.createElement('div');
            content_movie_title.classList.add('title', 'title--size-20', 'title--bold', 'title--black');
            content_movie_title.innerText = this.nt_lang_strings.translations.movie_title[window.current_lang];
            let content_pdfs = document.createElement('div');
            content_pdfs.classList.add('modal__pdfs');
            let content_pdfs_title = document.createElement('div');
            content_pdfs_title.classList.add('title', 'title--size-20', 'title--bold', 'title--black');
            content_pdfs_title.innerText = this.nt_lang_strings.translations.files_title[window.current_lang];
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--text')
            modal.setAttribute('data-element', 'modal');

            if (this.content !== null) {
                content_text.innerHTML = this.content;
                content.appendChild(content_text);
            }

            if (this.video_id !== '') {
                let w = (window.innerWidth/2) - 50;
                let h = (w / 16) * 9;
                let modify = 1;

                if (window.innerWidth <= 600) {
                    w = window.innerWidth - 50;
                    h = (w / 16) * 9;
                    modify = 1;
                }
                content_movie.appendChild(content_movie_title);
                const video = document.createElement('iframe');
                video.setAttribute('src', 'https://www.youtube.com/embed/' + this.video_id + '?rel=0&autoplay=0');
                video.setAttribute('width', w * modify);
                video.setAttribute('height', h * modify);
                video.setAttribute('frameborder', 0);
                content_movie.appendChild(video);
                content.appendChild(content_movie);
            }

            if (this.pdfs.length > 0) {
                let pdfs_html = '<ul>';
                this.pdfs.forEach(item => {
                    pdfs_html += '<li><a href="' + item.url + '" target="_blank">' + item.title + '</a></li>';
                });
                pdfs_html += '</ul>'

                content_pdfs.innerHTML = pdfs_html;
                content.appendChild(content_pdfs_title);
                content.appendChild(content_pdfs);
            }

            content.classList.add('modal__content');

            modal.appendChild(close);
            modal.appendChild(content);
            div.appendChild(modal);

            let instance = basicLightbox.create(div);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
    }
}
export default ExtendedMaterials;