class Dictionary {
    constructor(selector) {
        this.selector = selector;
        this.type = this.selector.getAttribute('data-type');
        this.alphabet_container = this.selector.querySelector('[data-alphabet-container]');
        this.words_container = this.selector.querySelector('[data-words-container]');
        this.letters = null;
        this.words = this.words_container.querySelectorAll('[data-word]');
        this.content_container = this.selector.querySelector('[data-content]');
        this.cache = {};
        this.isMobile = false;
        this.letters_list = [];
        this.lang = this.selector.getAttribute('data-lang');

        if(this.alphabet_container !== null) {
            this.letters = this.alphabet_container.querySelectorAll('[data-letter]');
            if (this.letters.length > 0) {
                this.letters.forEach(letter => {
                    this._clickLetterEvent = this._clickLetterEvent.bind(this);
                    letter.addEventListener('click', this._clickLetterEvent);
                });
            }
        }
        if (this.words !== null) {
            this.words.forEach(word => {
                this._clickWordEvent = this._clickWordEvent.bind(this);
                word.addEventListener('click', this._clickWordEvent);

                this.letters_list.push(word.getAttribute('data-first-letter'));
            });
        }

        this._clickFirstElement();

        if (window.innerWidth <= 500) {
            this.isMobile = true;
        }

        if (this.letters_list !== null) {
            let unique = this.letters_list.filter((val, ind) => {
                return this.letters_list.indexOf(val) === ind;
            });
            if (unique !== null && this.letters !== null) {
                this.letters.forEach(letter => {
                    let attr = letter.getAttribute('data-id');
                    if (attr !== '*') {
                        if (!unique.includes(attr)) {
                            letter.classList.add('dictionary__letter--disable');
                        }
                    }
                });
            }
        }
    }
    _clickLetterEvent(e) {
        e.preventDefault();

        if (!e.target.classList.contains('dictionary__letter--disable')) {
            this._setActiveLetter(e.target);

            let letter = e.target.getAttribute('data-id');
            this._syncWords(letter);
        }
    }
    _clickWordEvent(e) {
        e.preventDefault();

        let post_id = e.target.getAttribute('data-post-id');
        this._loadPost(post_id);

        this._setActiveWord(e.target);
    }
    _setActiveWord(item) {
        if (this.words !== null) {
            this.words.forEach(word => {
                if (word.classList.contains('dictionary__single-word--highlight')) {
                    word.classList.remove('dictionary__single-word--highlight');
                }
            });
        }
        item.classList.add('dictionary__single-word--highlight');

        setTimeout(() => {
            if (this.isMobile) {
                window.scrollTo({
                    top: window.pageYOffset + this.words_container.getBoundingClientRect().top + 140,
                    behavior: 'smooth'
                });
            }
        }, 100);
    }
    _setActiveLetter(item) {
        this._clearLetters();
        item.classList.add('dictionary__letter--active');
    }
    _clearLetters() {
        // this._clearContent();
        this._clearWords();
        if (this.letters !== null) {
            this.letters.forEach(letter => {
                if (letter.classList.contains('dictionary__letter--active')) {
                    letter.classList.remove('dictionary__letter--active');
                }
            });
        }
    }
    _clearWords() {
        // this._clearContent();
        if (this.words !== null) {
            this.words.forEach(word => {
                if (word.classList.contains('dictionary__single-word--active')) {
                    word.classList.remove('dictionary__single-word--active');
                }
                // if (word.classList.contains('dictionary__single-word--highlight')) {
                //     word.classList.remove('dictionary__single-word--highlight');
                // }
            });
        }
    }
    _clearContent() {
        this.content_container.innerHTML = '';
    }
    _syncWords(letter) {
        if (letter === '*') {
            if (this.words !== null) {
                this.words.forEach(word => {
                    word.classList.add('dictionary__single-word--active');
                });
            }
        } else {
            let words = this.words_container.querySelectorAll('[data-first-letter="' + letter + '"]');
            if (words !== null) {
                this._clearWords();
                words.forEach(word => {
                    word.classList.add('dictionary__single-word--active');
                });
            }
        }
    }
    _getFromAPI(post_id) {
        if (this.cache.hasOwnProperty('post_' + post_id)) {
            return new Promise((resolve, reject) => {
                resolve(this.cache['post_' + post_id]);
            });
        }


        let url = '/wp-json/wp/v2/concept?per_page=1&_embed&include[]=' + post_id + '&type=' + this.type;
        if(this.lang !== 'pl'){
            url = '/' + this.lang + url;
        }
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _loadPost(post_id){
        this._getFromAPI(post_id).then(response => {
            if(response.length > 0){
                if (!this.cache.hasOwnProperty('post_' + post_id)) {
                    this.cache['post_' + post_id] = response;
                }
                this._generateContent(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
        console.log('Cache: ', this.cache);
    }
    _generateContent(res) {
        let content = document.createElement('div');
        content.classList.add('dictionary__content');

        let close = document.createElement('a');
        close.classList.add('dictionary__close');
        close.innerHTML = '×';

        close.addEventListener('click', e => {
            e.preventDefault();

            this.content_container.innerHTML = '';

            if (this.words !== null) {
                this.words.forEach(word => {
                    if (word.classList.contains('dictionary__single-word--highlight')) {
                        word.classList.remove('dictionary__single-word--highlight');
                    }
                });
            }

            setTimeout(() => {
                if (this.isMobile) {
                    window.scrollTo({
                        top: window.pageYOffset + this.alphabet_container.getBoundingClientRect().top,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        });

        let image = null;
        if (typeof res[0]._embedded !== 'undefined') {
            image = document.createElement('img');
            image.classList.add('dictionary__image');
            image.setAttribute('alt', res[0].title.rendered);
            image.setAttribute('src', res[0]._embedded["wp:featuredmedia"][0].source_url);
        }

        let title = document.createElement('h3');
        title.classList.add('dictionary__title', 'title', 'title--red', 'title--uppercase');
        title.innerHTML = res[0].title.rendered;

        let text = document.createElement('div');
        text.classList.add('dictionary__text');
        text.innerHTML = res[0].content.rendered;

        if (image !== null) {
            content.appendChild(image);
        }
        content.appendChild(title);
        content.appendChild(text);

        this.content_container.innerHTML = '';
        this.content_container.appendChild(close);
        this.content_container.appendChild(content);
    }
    _noResults(){
        let text = document.createElement('div');
        // text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Brak wyników.';

        // this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('div');
        // text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Wystąpił bląd.';

        // this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _clickFirstElement() {
        let first_element = this.words_container.querySelector('[data-word]:first-of-type');
        if (first_element !== null) {
            first_element.click();
        }
    }
}
export default Dictionary;