import * as basicLightbox from "basiclightbox";

class Artifacts {
    constructor(selector) {
        this.selector = selector;
        this.postId = this.selector.getAttribute('data-post-id');
        this.type = this.selector.getAttribute('data-type');
        this.page = 1;
        this.max = this.selector.getAttribute('data-max');
        this.modal = null;
        this.arrow_prev = null;
        this.arrow_next = null;
        this.cache = {};

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
    }
    _clickEvent(e) {
        e.preventDefault();

        this._loadPosts();
    }
    _getFromAPI() {
        if (this.cache.hasOwnProperty('page_' + this.page)) {
            return new Promise((resolve, reject) => {
                resolve(this.cache['page_' + this.page]);
            });
        }

        let url = '/wp-json/wp/v2/'+ this.type + '?per_page=1&page=' + this.page + '&_embed&connected_lesson=' + this.postId + '&orderby=date&order=asc';
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _loadPosts(){
        this._getFromAPI().then(response => {
            if(response.length > 0){
                if (!this.cache.hasOwnProperty('page_' + this.page)) {
                    this.cache['page_' + this.page] = response;
                }
                this._generateContent(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
        console.log(this.cache);
    }
    _generateContent(res) {
        let container = document.createElement('div');
        container.classList.add('artifact');

        let image = null;
        if (typeof res[0]._embedded !== 'undefined') {
            image = document.createElement('img');
            image.classList.add('artifact__image');
            image.setAttribute('alt', res[0].title.rendered);
            image.setAttribute('src', res[0]._embedded["wp:featuredmedia"][0].source_url);
        }

        let title = document.createElement('h3');
        title.classList.add('artifact__title');
        title.innerHTML = res[0].title.rendered;

        let content = document.createElement('div');
        content.classList.add('artifact__content');
        content.innerHTML = res[0].content.rendered;

        let arrows_container = document.createElement('div');
        arrows_container.classList.add('artifact__arrows');

        this.arrow_prev = document.createElement('a');
        this.arrow_prev .classList.add('artifact__arrow', 'artifact__arrow--prev');
        this.arrow_prev .innerText = '<';

        this._clickArrowPrevEvent = this._clickArrowPrevEvent.bind(this);
        this.arrow_prev.addEventListener('click', this._clickArrowPrevEvent);

        this.arrow_next = document.createElement('a');
        this.arrow_next.classList.add('artifact__arrow', 'artifact__arrow--next');
        this.arrow_next.innerText = '>';

        this._clickArrowNextEvent = this._clickArrowNextEvent.bind(this);
        this.arrow_next.addEventListener('click', this._clickArrowNextEvent);

        arrows_container.appendChild(this.arrow_prev);
        arrows_container.appendChild(this.arrow_next);

        if (image !== null) {
            content.insertBefore(image, content.querySelector('p'));
        }
        content.insertBefore(title, content.querySelector('p'));

        container.appendChild(content);
        container.appendChild(arrows_container);

        this._generateModal(container);
    }
    _generateModal(inside_content) {
        if (this.modal == null) {
            console.log('1');

            const div = document.createElement('div');
            const modal = document.createElement('div');
            const content = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--text');
            modal.setAttribute('data-element', 'modal');

            content.classList.add('modal__content');
            content.append(inside_content);

            modal.appendChild(close);
            modal.appendChild(content);
            div.appendChild(modal);

            this.modal = basicLightbox.create(div);
            this.modal.show();

            close.addEventListener('click', (e) => {
                this.modal.close();
                this.modal.innerHTML = '';
                this.modal = null;
            });
        } else {
            let content = this.modal.element().querySelector('.modal__content');
            content.innerHTML = '';
            content.append(inside_content);
        }
    }
    _clickArrowPrevEvent(e) {
        e.preventDefault();

        if (this.page > 1) {
            this.page--;
        } else this.page = this.max;
        this._loadPosts();
    }
    _clickArrowNextEvent(e) {
        e.preventDefault();

        if (this.page < this.max) {
            this.page++;
        } else this.page = 1;
        this._loadPosts();
    }
    _noResults(){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Brak wyników.';

        // this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Wystąpił bląd.';

        // this._hideLoaderAnimation();
        this.isLoading = false;
    }
}
export default Artifacts;